import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
import Logo from "../../assets/img/logo-dp.png";

import { loginApi } from "api/auth";

import { loginValidationSchema } from "utils/validationSchema";
import { TOKEN_NAME } from "utils/constants";

const Login = () => {
  // const [inputType, setInputType] = useState("password");
  const [inputs, setInputs] = useState({
    usernameOrEmail: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (document) {
      document.title = "Login";
    }
  }, []);

  const loginUser = useMutation(
    "loginUser",
    async (data) => {
      const response = await loginApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(TOKEN_NAME, data.data.token);
        window.location.href = "/home";
      },
      onError: (error) => {
        setErrorMessage(error?.response?.data?.message);
      },
    }
  );

  const handleInputChange = (event) => {
    event.persist();
    setErrors({});
    setInputs((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage(null);
  };

  const handleSubmit = () => {
    setErrorMessage("");
    loginValidationSchema
      .validate(inputs, { abortEarly: false })
      .then(() => {
        const { usernameOrEmail, password } = inputs;
        loginUser.mutate({ usernameOrEmail, password });
        setErrors("");
      })
      .catch((err) => {
        let errList = {};
        err.inner.forEach((e) => {
          errList = { ...errList, [e.path]: e.message };
        });
        setErrors(errList);
      });
  };

  // const handleShowPassword = () => {
  //   setInputType(inputType === "password" ? "text" : "password");
  // };

  useEffect(() => {
    document.body.style.background = "#fff";
    document.title = "DataPlora - Login";
  }, []);

  // const handleSubmit = () => {
  //   setError("");
  //   const getUser = demoCreds.find(
  //     (cred) => cred.email === inputs.usernameOrEmail && cred.password === inputs.password
  //   );    

  //   if (getUser) {
  //     window.location.href = "/dashboard";
  //     localStorage.setItem("edaappemail", inputs.usernameOrEmail);
  //     localStorage.setItem("edaaccestoken", btoa(inputs.usernameOrEmail));
  //   } else {
  //     setError("Invalid email/password");
  //   }
  // };

  return (
    <>
      <main className="main-content">
        <div className="signUP-admin">
          <div className="container-fluid" style={{ minHeight: "100vh" }}>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-7 col-md-7 col-sm-8 mt-50">
                <div className="signUp-admin-right signIn-admin-right  p-md-40 p-10">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-12">
                      <div className="edit-profile mt-md-25 mt-0">
                        <div className="card border-0">
                          <div className="text-center">
                            <a href="#?"><img src={Logo} width="100px" alt="dp-logo"/></a>
                          </div>
                          <div className="card-header border-0  pb-md-15 pb-10 pt-md-20 pt-10 ">
                            <div className="edit-profile__title">
                              <h6>Welcome, Login</h6>
                            </div>
                          </div>

                          <div className="card-body">
                            <p
                              style={{
                                color: "red",
                              }}
                            >
                              {errors?.email || errors?.password || errorMessage}
                            </p>
                            <div className="edit-profile__body">
                              <div className="form-group mb-20">
                                <label for="usernameOrEmail">
                                  Email Address
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="usernameOrEmail"
                                  name="usernameOrEmail"
                                  placeholder="Email Address"
                                  value={inputs.usernameOrEmail}
                                  onChange={handleInputChange}
                                />
                              </div>
                              <div className="form-group mb-15">
                                <label for="password-field">Password</label>
                                <div className="position-relative">
                                  <input
                                    id="password-field"
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    value={inputs.password}
                                    onChange={handleInputChange}
                                    placeholder="Password"
                                  />
                                  <div className=" text-light fs-16 field-icon toggle-password2"></div>
                                </div>
                              </div>
                              {/* <div className="signUp-condition signIn-condition">
                                <div className="checkbox-theme-default custom-checkbox ">
                                  <input
                                    className="checkbox"
                                    type="checkbox"
                                    id="check-1"
                                  />
                                  <label for="check-1">
                                    <span className="checkbox-text">
                                      Keep me logged in
                                    </span>
                                  </label>
                                </div>
                                <a href="forget-password.html">
                                  forget password
                                </a>
                              </div> */}
                              <div className="button-group d-flex pt-1 justify-content-md-start justify-content-center">
                                <button
                                  className="btn btn-primary btn-default btn-squared mr-15 text-capitalize lh-normal px-50 py-15 signIn-createBtn"
                                  onClick={handleSubmit}
                                  disabled={loginUser.isLoading }
                                >
                                  {loginUser.isLoading ? "signing in...": "sign in"}
                                </button>
                              </div>
                              <p className="social-connector text-center mb-sm-25 mb-15  mt-sm-30 mt-20">
                                <span>Or</span>
                              </p>
                              <div className=" text-center">
                                <p className="mb-0 text-center">
                                  Don't have an account? &nbsp;
                                  <a
                                    href="/signup"
                                    className="color-primary"
                                    rel="noreferrer"
                                  >
                                    Create Account
                                  </a>
                                </p>
                              </div>
                              <br />
                              <div className=" text-center">
                                <p className="mb-0 text-center">
                                  <a href="https://www.dataplora.com/" className="color-primary">
                                    Go Home
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

// const styles = {
//   errorBox: {
//     color: "red",
//     textAlign: "left",
//     marginBottom: "20px",
//   },
//   errorMessageBox: {
//     color: "red",
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   successBox: {
//     color: "green",
//     textAlign: "center",
//     marginBottom: "20px",
//   },
// };

export default Login;
