import axios from "axios";
import { getToken } from "../utils/authHandler";
import clearLocalStorage from "utils/clearData";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token) {
      // add token to header
      // sample code: config.headers['header name'] = `expected token header format e.g Bearer ${token}`;
      config.headers["x-access-token"] = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// this prevents react query from catching the errors that's why its commented out
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if (error.response.status === 403) {
    //   console.log('permission denied');
    // }
    if (
      error.response.status === 401 &&
      error?.response?.config?.url === "/auth/login"
    ) {
      return Promise.reject(error);
    }
    if (
      error.response.status === 401 &&
      error?.response?.config?.url !== "/auth/login"
    ) {
      clearLocalStorage();
      window.location.href = "/login";
    }
    return Promise.reject(error);

    // if (error.response.status === 400) {
    //   console.log(error, 'res2')
    //   return error.response
    // }
    // if (error.response.status === 500 || error.response.status === 503) {
    //   console.log('service not available');
    // }
  }
);

export default instance;
