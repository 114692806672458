import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { uniPieApi } from "api/explore";
import Loader from "../../../assets/img/loader.svg";
import { getUserInfo } from "utils/authHandler";

const PieCharts = ({ match, id, dataColumns }) => {
  const [error, setError] = useState("");
  const [dataInfo, setDataInfo] = useState(null);
  const [dataInfoSingle, setDataInfoSingle] = useState("");
  const [plotColumn, setPlotColumn] = useState(dataColumns[0]);
  const dataId = match?.params?.id || id;

  const { refetch: getDataInfoFetch, isLoading } = useQuery(
    "getPieChartsData",
    async () => {
      setError("");
      const getUserEmail = getUserInfo()?.email;
      const response = await uniPieApi(getUserEmail, dataId, plotColumn);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        }
        console.log(data?.data?.plots, "plot");
        if (data?.data?.plot) {
          setDataInfoSingle(
            process.env.REACT_APP_BASE_URL + "get_image/" + data?.data?.plot
          );
          setDataInfo(null)
        } else {
          setDataInfo(data?.data?.plots);
          setDataInfoSingle("")
        }
      },
      onError: () => {
        setError("Oops! Failed to load data visualization");
      },
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataInfoFetch();
    }, 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getDataInfoFetch]);

  const handleSelectChange = (event) => {
    setPlotColumn(event.target.value);
  };

  const getBoxPlot = () => {
    getDataInfoFetch();
  };

  console.log(dataInfo, "dataInfo");
  return (
    <>
      <div className="col-12 mb-25 text-center">
        {error && (
          <p
            style={{
              color: "red",
            }}
          >
            {error}
          </p>
        )}
        <div
          className="flex"
          style={{
            display: "flex",
            gap: "15px",
            alignItems: "baseline",
            color: "#5a5f7d",
            fontSize: "14px",
            marginTop: "15px",
          }}
        >
          <p>Show a pie chart for:</p>
          <select
            className="form-control"
            style={{ width: "fit-content" }}
            value={plotColumn}
            onChange={handleSelectChange}
          >
            {dataColumns.map((option, index) => (
              <option key={index}>{option}</option>
            ))}
          </select>
          <button className="btn btn-sm btn-primary" onClick={getBoxPlot}>
            Show
          </button>
        </div>
        {isLoading && (
          <div>
            <img src={Loader} alt="loader" className="svg" height="100px" />
          </div>
        )}
        {!isLoading && dataInfoSingle && (
          <div>
            <br />
            <img src={dataInfoSingle} alt="chart" width="80%" />
          </div>
        )}
        <div class="row">
          {dataInfo &&
            dataInfo.map((chart, index) => (
              <div key={`piechart-` + index} className="col-md-6">
                <br />
                <img
                  src={process.env.REACT_APP_BASE_URL + "get_image/" + chart}
                  alt="chart"
                  style={{
                    width: "100%",
                    margin: "0px"
                  }}
                />
              </div>
            ))}
        </div>
        <br />
      </div>
    </>
  );
};

export default PieCharts;
