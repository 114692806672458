import axios_node from "../config/axios_node";

export const signupApi = async (inputs) => {
  const request = await axios_node.post("/auth/signup/email", inputs);
  return request;
};

export const loginApi = async (inputs) => {
  const request = await axios_node.post("/auth/login/email", inputs);
  return request;
};

export const passwordResetApi = async (inputs) => {
  const request = await axios_node.post("/auth/password/reset", inputs);
  return request;
};

export const confirmPasswordResetApi = async (inputs) => {
  const request = await axios_node.put("/auth/password/completereset", inputs);
  return request;
};

export const verifyApi = async (input) => {
  const request = await axios_node.post("/auth/verify/login", {
    email: input.email,
    mid: input.magicId,
  });
  return request;
};
