import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";

import NavBar from "components/NavBar/NavBar";
// import SideBar from "components/SideBar/SideBar";
// import Databank from "../../assets/img/svg/idea.svg";
import FAQImage from "../../assets/img/svg/chat.svg";
import DocImage from "../../assets/img/svg/documents.svg";
import Footer from "components/Footer/Footer";

import { uploadApi } from "api/explore";
import { getUserInfo } from "utils/authHandler";

const Main = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [name, setName] = useState("");

  const handleFileChange = (event) => {
    setError("");
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleInputTitleChange = (event) => {
    event.persist();
    setError("");
    setTitle(event.target.value);
  };

  const uploadData = useMutation(
    "addDataFile",
    async (data) => {
      const response = await uploadApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        window.location.href = "exploratory/bdi/" + data.data.id;
      },
      onError: () => {
        setError("Error: Automator failed to start. Try Again!");
      },
    }
  );

  const handleUpload = () => {
    setError("");
    if (!title.trim()) {
      return setError("Title is required!");
    }
    if (!selectedFile) {
      return setError("Please select a file!");
    }

    const fileType = selectedFile.type;

    if (fileType !== "text/csv") {
      return setError("Oops! Only a .CSV file is allowed!");
    }
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("email", getUserInfo()?.email);
    formData.append("title", title.trim());

    uploadData.mutate(formData);
  };

  useEffect(() => {
    setName(getUserInfo()?.email?.split("@")[0]);
  }, []);

  return (
    <>
      <div className="mobile-author-actions"></div>
      <NavBar />
      <main className="main-content">
        {/* <SideBar /> */}

        <div
          className="contents"
          style={{
            paddingLeft: "15px",
          }}
        >
          <div className="crm sales">
            <div className="container-fluid">
              <div className="col-xxl-12">
                <div className="banner-feature--14 card mb-25 mt-25">
                  <div className="row justify-content-center">
                    <div className="col-md-9">
                      <div className="row align-items-center py-10">
                        <div className="col-md-6">
                          <div className="card-body d-inline-block">
                            <h1 className="d-flex">Welcome, {name}</h1>
                            <p>
                              Automate time-consuming data cleaning tasks and
                              focus on deriving value from your data.
                            </p>
                            {/* <div className="d-flex justify-content-start">
                              <button
                                className="btn btn-primary btn-default btn-squared btn-shadow-primary"
                                type="button"
                              >
                                + Start New Explorator
                              </button>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block">
                          <div className="banner-feature__shape d-flex justify-content-center">
                            <img
                              src="https://static.vecteezy.com/system/resources/previews/020/716/201/non_2x/illustration-of-business-man-doing-data-analysis-using-magnifying-glass-data-analytics-makes-predictions-of-future-business-free-png.png"
                              alt="img"
                              height="200px"
                              className="svg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xxl-7 mb-25">
                    <div className="card border-0">
                      <div className="card-header">
                        <h6>Data Automator</h6>
                        <div className="card-extra">
                          <ul
                            className="card-tab-links mr-3 nav-tabs nav"
                            role="tablist"
                          >
                            <li>
                              <a
                                className="active"
                                href="#t_selling-today"
                                data-toggle="tab"
                                id="t_selling-today-tab"
                                role="tab"
                                aria-selected="true"
                              >
                                All
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="card  card-default card-md mb-4">
                          <div className="card-body pb-md-40 pt-md-3">
                            <p
                              style={{
                                color: "red",
                                fontSize: "14px",
                              }}
                            >
                              {error}
                            </p>
                            <div className="form-group mb-20">
                              <label
                                htmlFor="title"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Title *:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="title"
                                placeholder="Title"
                                name="title"
                                value={title}
                                onChange={handleInputTitleChange}
                              />
                            </div>
                            <div className="custom-file">
                              <label
                                htmlFor="formFile"
                                className="form-label"
                                style={{
                                  fontSize: "14px",
                                }}
                              >
                                Select your CSV file *:
                              </label>
                              <input
                                className="form-control"
                                type="file"
                                id="formFile"
                                name="datafile"
                                onChange={handleFileChange}
                              />
                            </div>
                            <div className="layout-button mt-25">
                              <button
                                href="exploratory/1"
                                className="btn btn-primary btn-default btn-squared px-30"
                                style={{ color: "white;" }}
                                onClick={handleUpload}
                                disabled={uploadData.isLoading}
                              >
                                <span
                                  data-feather="database"
                                  className="nav-icon"
                                ></span>
                                {uploadData.isLoading
                                  ? "Initialzing Data Cleaner"
                                  : "Run Data Cleaning"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="card-body p-0">
                        <div className="tab-content">
                          <div
                            className="tab-pane fade active show"
                            id="t_selling-today"
                            role="tabpanel"
                            aria-labelledby="t_selling-today-tab"
                          >
                            <div className="selling-table-wrap">
                              <div className="table-responsive">
                                <table className="table table--default">
                                  <thead>
                                    <tr>
                                      <th>Title </th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="selling-product-img d-flex align-items-center">
                                          <span>My sample test data</span>
                                        </div>
                                      </td>

                                      <td>
                                        <a href="#?">View</a>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-xxl-5">
                    <div className="row">
                      {/* <div className="col-xxl-6 col-lg-4 mb-25">
                        <div className="card shadow-none border-0">
                          <div className="card-body banner-feature--15">
                            <div className="banner-feature__shape d-flex justify-content-center">
                              <div className="wh-80 bg-primary rounded-circle content-center">
                                <img src={Databank} alt="img" className="svg" />
                              </div>
                            </div>
                            <div className="pb-md-0 pb-30 text-center">
                              <h4>DataBank</h4>
                              <p>Access datasets for your use</p>
                            </div>
                            <div className="content-center mt-25">
                              <button className="btn btn-primary btn-sm btn-squared btn-transparent-primary rounded-pill">
                                Learn More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div className="col-xxl-6 col-lg-4 mb-25">
                        <div className="card shadow-none border-0">
                          <div className="card-body banner-feature--15">
                            <div className="banner-feature__shape d-flex justify-content-center">
                              <div className="wh-80 bg-success rounded-circle content-center">
                                <img src={DocImage} alt="img" className="svg" />
                              </div>
                            </div>
                            <div className="pb-md-0 pb-30 text-center">
                              <h4>Documentation</h4>
                              <p>Coming soon (In development)</p>
                            </div>
                            <div className="content-center mt-25">
                              <button className="btn btn-primary btn-sm btn-squared btn-transparent-primary rounded-pill">
                                Learn More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-lg-4 mb-25">
                        <div className="card shadow-none border-0">
                          <div className="card-body banner-feature--15">
                            <div className="banner-feature__shape d-flex justify-content-center">
                              <div className="wh-80 bg-info rounded-circle content-center">
                                <img src={FAQImage} alt="img" className="svg" />
                              </div>
                            </div>
                            <div className="pb-md-0 pb-30 text-center">
                              <h4>FAQ</h4>
                              <p>Coming soon (In development)</p>
                            </div>
                            <div className="content-center mt-25">
                              <button className="btn btn-primary btn-sm btn-squared btn-transparent-primary rounded-pill">
                                Learn More
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-xxl-6 col-lg-6 mb-25">
                        <div className="card h-100">
                          <div className="card-body p-30">
                            <div className="pricing d-flex align-items-center">
                              <span className=" pricing__tag color-primary order-bg-opacity-primary rounded-pill ">
                                Basic Plan
                              </span>
                            </div>
                            <div className="pricing__price rounded">
                              <p className="pricing_value display-3 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                                <sup>$</sup>5
                                <small className="pricing_user">
                                  Per month
                                </small>
                              </p>
                            </div>
                            <div className="pricing__features">
                              <ul>
                                <li>
                                  <span className="fa fa-check"></span>100GB
                                  file space
                                </li>

                                <li>
                                  <span className="fa fa-check"></span>
                                  Unliimited projects
                                </li>
                                <li>
                                  <span className="fa fa-check"></span>Unlimited
                                  Access to databank
                                </li>
                                <li>
                                  <span className="fa fa-check"></span>Multiple
                                  file types support
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="price_action d-flex pb-30 pl-30">
                            <button className="btn btn-transparent-primary btn-default btn-squared text-capitalize px-30">
                              Upgrade
                            </button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Main;
