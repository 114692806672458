import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import NavBar from "components/NavBar/NavBar";
// import SideBar from "components/SideBar/SideBar";
import Footer from "components/Footer/Footer";
import Loader from "../../assets/img/loader.svg";
import InfoIcon from "../../assets/img/info-icon.png";

import { shapeApi } from "api/explore";
import Shape from "./Shape";
import AllColumns from "./AllColumns";
import DataInfo from "./DataInfo";
import Head from "./Head";
import Tail from "./Tail";
import IsNull from "./IsNull";
import ExploratoryVisualize from "./ExploratoryVisualize";

import "./css/Exploratory.css";
import Describe from "./Describe";
import StatisticExploratory from "./StatisticExploratory";
import { getUserInfo } from "utils/authHandler";
import CleanDataInsightExploratory from "./CleanDataInsightExploratory";

const Exploratory = ({ match }) => {
  const [dataShape, setDataShape] = useState({
    shape: "",
    rows: "",
    cols: "",
  });
  const [error, setError] = useState("");
  const [view, setView] = useState("shape");

  const dataId = match?.params?.id;
  const folderId = match?.params?.folder;

  const expectedFolderIds = ["bdi", "vuni", "vbiv", "sti", "cde"];

  if (!expectedFolderIds.includes(folderId)) {
    window.location = "/404";
  }

  const {
    refetch: getDataShapeFetch,
    isLoading: isLoadingShape,
    isError,
  } = useQuery(
    "getDataShape",
    async () => {
      setError("");
      const getUserEmail = getUserInfo()?.email;
      const response = await shapeApi(getUserEmail, dataId);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        } else {
        }

        setDataShape((preState) => ({
          ...preState,
          shape: data?.data?.shape,
          rows: data?.data?.rows,
          cols: data?.data?.cols,
        }));
        // setAllTasks(data?.data?.tasks);
      },
      onError: () => {
        setError("Oops! Failed to load shape.");
      },
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataShapeFetch();
    }, 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getDataShapeFetch]);

  const getCurrentView = () => {
    switch (view) {
      case "shape":
        return (
          <Shape
            cols={dataShape.cols}
            rows={dataShape.rows}
            shape={dataShape.shape}
          />
        );
      case "columns":
        return <AllColumns id={dataId} />;
      case "info":
        return <DataInfo id={dataId} />;
      case "head":
        return <Head id={dataId} />;
      case "tail":
        return <Tail id={dataId} />;
      case "isnull":
        return <IsNull id={dataId} />;
      case "describe":
        return <Describe id={dataId} />;
      default:
        return <div>No view</div>;
    }
  };

  const generalStatButtons = [
    {
      name: "Get Data Shape",
      id: "shape",
    },
    {
      name: " View All Columns",
      id: "columns",
    },
    {
      name: "View Data Info",
      id: "info",
    },
    {
      name: "Data Head (First 5)",
      id: "head",
    },
    {
      name: "Data Tail (Last 5)",
      id: "tail",
    },
    {
      name: "View Describe Info",
      id: "describe",
    },
    {
      name: "View isNull Total",
      id: "isnull",
    },
  ];

  const updateView = (id) => {
    setView(id);
  };

  const buttons = generalStatButtons.map((button) => (
    <button
      key={button.id}
      className={`btn ${
        view === button.id ? "btn-dark" : "btn-light"
      } btn-default btn-squared`}
      onClick={() => updateView(button.id)}
    >
      {button.name}
    </button>
  ));

  const title = dataId
    .split("__")[1]
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());

  return (
    <>
      <div className="mobile-author-actions"></div>
      <NavBar />
      <main className="main-content">
        {/* <SideBar /> */}
        <div className="contents">
          <div className="atbd-page-content">
            <div className="container-fluid">
              <div className="fileM-wrapper">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="breadcrumb-main">
                      <h4 className="text-capitalize breadcrumb-title">
                        Explorator for: {title}
                      </h4>
                      <div className="breadcrumb-action justify-content-center flex-wrap">
                        {/* <div className="action-btn">
                          <a
                            href="#?"
                            className="btn btn-sm btn-primary btn-add"
                          >
                            <i className="la la-plus"></i>Invite A Collaborator
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="fileM-contents">
                      <div className="row">
                        <div className=" col-20 col-lg-3 col-sm-4">
                          <div className="fileM-sidebar-wrapper mb-30">
                            <div
                              className="fileM-sidebar"
                              style={{ height: "auto" }}
                            >
                              <div className="card border-0 shadow-none">
                                <div className="card-body px-15 pt-30">
                                  <div className="fileM-types">
                                    <div className="sidebar__menu-group">
                                      <ul className="sidebar_nav">
                                        <li className="menu-title">
                                          <span>Activities:</span>
                                        </li>
                                        <li>
                                          <a
                                            href={`/exploratory/bdi/${dataId}`}
                                            className=""
                                          >
                                            <span
                                              data-feather="file"
                                              className="nav-icon"
                                            ></span>
                                            <span
                                              className="menu-text"
                                              style={{
                                                color:
                                                  folderId === "bdi"
                                                    ? "#5f63f2"
                                                    : "",
                                              }}
                                            >
                                              Basic Data Insights
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={`/exploratory/sti/${dataId}`}
                                            className=""
                                          >
                                            <span
                                              data-feather="file"
                                              className="nav-icon"
                                            ></span>
                                            <span
                                              className="menu-text"
                                              style={{
                                                color:
                                                  folderId === "sti"
                                                    ? "#5f63f2"
                                                    : "",
                                              }}
                                            >
                                              Statistical Insights
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            href={`/exploratory/cde/${dataId}`}
                                            className=""
                                          >
                                            <span
                                              data-feather="file"
                                              className="nav-icon"
                                            ></span>
                                            <span
                                              className="menu-text"
                                              style={{
                                                color:
                                                  folderId === "cde"
                                                    ? "#5f63f2"
                                                    : "",
                                              }}
                                            >
                                              Clean Data Studio
                                            </span>
                                          </a>
                                        </li>
                                        <li
                                          className={`has-child ${
                                            folderId === "vuni" ? "open" : ""
                                          }`}
                                        >
                                          <a href="#?" className="">
                                            <span
                                              data-feather="file"
                                              className="nav-icon"
                                            ></span>
                                            <span className="menu-text">
                                              Visualizations
                                            </span>
                                            <span className="toggle-icon"></span>
                                          </a>
                                          <ul>
                                            <li>
                                              <a
                                                className=""
                                                href={`/exploratory/vuni/${dataId}`}
                                              >
                                                <span
                                                  data-feather="folder"
                                                  className="nav-icon"
                                                ></span>
                                                <span
                                                  className="menu-text"
                                                  style={{
                                                    color:
                                                      folderId === "vuni"
                                                        ? "#5f63f2"
                                                        : "",
                                                  }}
                                                >
                                                  Univariate{" "}
                                                </span>
                                              </a>
                                            </li>
                                            <li>
                                              <a className="" href="#?">
                                                <span
                                                  data-feather="folder"
                                                  className="nav-icon"
                                                ></span>
                                                Bivariate
                                              </a>
                                            </li>
                                          </ul>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="fileM-progress">
                                    <div className="user-group-progress-bar">
                                      <p>Projects</p>
                                      <div className="progress-wrap d-flex align-items-center mb-0">
                                        <div className="progress">
                                          <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{ width: "3%" }}
                                            aria-valuenow="84"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                          ></div>
                                        </div>
                                        <span className="progress-percentage">
                                          0.3%
                                        </span>
                                      </div>
                                      <span className="">
                                        1 of 300 projects used
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {folderId === "bdi" && (
                          <div className="col-79 col-lg-9 col-sm-8">
                            <div className="fileM-grid-wrapper mb-30 ">
                              <div
                                className="tab-content"
                                id="pills-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id="pills-home"
                                  role="tabpanel"
                                  aria-labelledby="pills-home-tab"
                                >
                                  <h6 className="fileM-wrapper__title">
                                    <img
                                      src={InfoIcon}
                                      alt="info"
                                      height="25px"
                                    />{" "}
                                    &nbsp; Basic Data Insights
                                  </h6>
                                  <div className="fileM-wrapper">
                                    <div className="atbd-button-list d-flex flex-wrap">
                                      <div className="button-list-container">
                                        <div className="button-list">
                                          {buttons}
                                        </div>
                                      </div>
                                    </div>
                                    <hr style={{ margin: "5px" }} />
                                    {isLoadingShape && (
                                      <div>
                                        <img
                                          src={Loader}
                                          alt="loader"
                                          className="svg"
                                          height="100px"
                                        />
                                      </div>
                                    )}
                                    {isError ||
                                      (error && (
                                        <p
                                          style={{
                                            color: "red",
                                          }}
                                        >
                                          {error}
                                        </p>
                                      ))}
                                    {!isLoadingShape ? (
                                      <div className="row">
                                        {getCurrentView()}
                                      </div>
                                    ) : (
                                      <div>
                                        {/* <img
                                        src={Loader}
                                        alt="loader"
                                        className="svg"
                                        height="100px"
                                      /> */}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {folderId === "vuni" && (
                          <ExploratoryVisualize dataId={dataId} />
                        )}
                        {folderId === "sti" && (
                          <StatisticExploratory dataId={dataId} />
                        )}
                        {folderId === "cde" && (
                          <CleanDataInsightExploratory dataId={dataId} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default Exploratory;
