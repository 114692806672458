import React from "react";

const Shape = ({ cols, rows, shape }) => {
  return (
    <>
      <div className="col-xxl-4 col-lg-4 col-md-4 mb-25">
        <figure className="feature-cards3" style={{ padding: "5px" }}>
          <figcaption>
            <h2>Shape:</h2>
            <p>{shape}</p>
          </figcaption>
        </figure>
      </div>
      <div className="col-xxl-4 col-lg-4 col-md-4 mb-25">
        <figure className="feature-cards3" style={{ padding: "5px" }}>
          <figcaption>
            <h2>Rows:</h2>
            <p>{rows}</p>
          </figcaption>
        </figure>
      </div>
      <div className="col-xxl-4 col-lg-4 col-md-4 mb-25">
        <figure className="feature-cards3" style={{ padding: "5px" }}>
          <figcaption>
            <h2>Columns:</h2>
            <p>{cols}</p>
          </figcaption>
        </figure>
      </div>
    </>
  );
};

export default Shape;
