import React from "react";

const Footer = () => {
  return (
    <>
            <footer className="footer-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="footer-copyright">
                  <p>
                    {new Date().getFullYear()} @<a href="#?">DataPlora</a>
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="footer-menu text-right">
                  <ul>
                    {/* <li>
                      <a href="#?">About</a>
                    </li>
                    <li>
                      <a href="#?">Team</a>
                    </li> */}
                    <li>
                      <a href="#?">Contact us: hello@dataplora.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div id="overlayer">
        <span className="loader-overlay">
          <div className="atbd-spin-dots spin-lg">
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
            <span className="spin-dot badge-dot dot-primary"></span>
          </div>
        </span>
      </div>
    </>
  );
};

export default Footer;
