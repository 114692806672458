import axios from "../config/axios";

export const uploadApi = async (inputs) => {
  const request = await axios.post("/uploader", inputs);
  return request;
};


export const shapeApi = async (email, id) => {
  const request = await axios.get(`/shape/${email}/${id}`);
  return request;
};

export const columnsApi = async (email, id) => {
  const request = await axios.get(`/columns/${email}/${id}`);
  return request;
};

export const infoApi = async (email, id) => {
  const request = await axios.get(`/info/${email}/${id}`);
  return request;
};

export const headApi = async (email, id, rownum) => {
  const request = await axios.get(`/head/${email}/${id}/${rownum}`);
  return request;
};

export const tailApi = async (email, id, rownum) => {
  const request = await axios.get(`/tail/${email}/${id}/${rownum}`);
  return request;
};

export const isNullTotalApi = async (email, id) => {
  const request = await axios.get(`/isnull/total/${email}/${id}`);
  return request;
};

export const describeApi = async (email, id) => {
  const request = await axios.get(`/describe/${email}/${id}`);
  return request;
};

export const corrApi = async (email, id) => {
  const request = await axios.get(`/corr/${email}/${id}`);
  return request;
};

export const uniBoxApi = async (email, id, plotColumn) => {
  const request = await axios.get(`/uni/boxplot/${email}/${id}/${plotColumn}`);
  return request;
};

export const uniPieApi = async (email, id, plotColumn) => {
  const request = await axios.get(`/uni/piechart/${email}/${id}/${plotColumn}`);
  return request;
};

export const numColsApi = async (email, id) => {
  const request = await axios.get(`/numcolumns/${email}/${id}`);
  return request;
};

export const uniCountApi = async (email, id, plotColumn) => {
  const request = await axios.get(`/uni/countplot/${email}/${id}/${plotColumn}`);
  return request;
};

export const missingPercentInfoApi = async (email, id) => {
  const request = await axios.get(`/missingpercentinfo/${email}/${id}`);
  return request;
};

export const missingPercentBoxApi = async (email, id) => {
  const request = await axios.get(`/missingpercent/barplot/${email}/${id}`);
  return request;
};

export const missingDroppedColumn = async (email, id) => {
  const request = await axios.get(`/missingdroppedcolumn/${email}/${id}`);
  return request;
};

export const imputeMissingStrategyOne = async (email, id) => {
  const request = await axios.get(`/imputemissingstrategyone/${email}/${id}`);
  return request;
};
