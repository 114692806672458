import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import "./MissingValues.css";
import {
  missingPercentInfoApi,
  missingPercentBoxApi,
  missingDroppedColumn,
  imputeMissingStrategyOne,
} from "api/explore";
import Loader from "../../../assets/img/loader.svg";
import DataProcess from "../../../assets/img/data-process.png";
import ExploreUnitHeader from "../ExploreUnitHeader";
import { getUserInfo } from "utils/authHandler";

const MissingValues = ({ match, id }) => {
  const [error, setError] = useState("");
  const [missingDataInfo, setMissingDataInfo] = useState([]);
  const [dataInfo, setDataInfo] = useState("");
  const [dataDropInfo, setDataDropInfo] = useState("");
  const [missingColumns, setMissingColumns] = useState([]);
  const [allMissingDataColumns, setAllMissingDataColumns] = useState([]);
  const [missingImputeData, setMissingImputeData] = useState("");
  const [imputedData, setImputedData] = useState([]);
  const [imputedValues, setImputedValues] = useState([]);
  const [shouldFetchStrategyData, setShouldFetchStrategyData] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false)

  const [action, setAction] = useState("view");
  const [secondaryDataView, setSecondaryDataView] = useState("missingpercent");

  const dataId = match?.params?.id || id;

  const { refetch: getDataInfoFetch, isLoading: isLoadingColumns } = useQuery(
    "getMissingValuesPercentData",
    async () => {
      const getUserEmail = getUserInfo()?.email;
      setSecondaryDataView("missingpercent");
      const response = await missingPercentInfoApi(getUserEmail, dataId);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        }
        setMissingColumns(data?.data?.columns);
        setMissingDataInfo(data?.data?.missing_info_percent);
      },
      onError: () => {
        setError("Oops! Failed to load data");
      },
    }
  );

  const { refetch: getMissingPercentage, isLoading: missingPercentLoading } =
    useQuery(
      "getMissingPercentPlotsData",
      async () => {
        setError("");
        const getUserEmail = getUserInfo()?.email;
        const response = await missingPercentBoxApi(getUserEmail, dataId);
        return response;
      },
      {
        onSuccess: (data) => {
          if (data?.data?.status === 404) {
            setError(data?.data?.message);
          }
          setDataInfo(
            process.env.REACT_APP_BASE_URL + "get_image/" + data?.data?.plot
          );
        },
        onError: () => {
          setError("Oops! Failed to load data visualization");
        },
      }
    );

  const {
    refetch: getMissingDroppedColumn,
    isLoading: missingDroppedColumnLoading,
  } = useQuery(
    "getMissingDroppedColumn",
    async () => {
      setError("");
      const getUserEmail = getUserInfo()?.email;
      const response = await missingDroppedColumn(getUserEmail, dataId);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        }
        setDataDropInfo(data?.data?.cleaned);
        setAllMissingDataColumns(data?.data?.all_columns_with_missing_data);
      },
      onError: () => {
        setError("Oops! Failed to load result");
      },
    }
  );

  const {
    refetch: getImputeMissingStrategyOne,
    isLoading: imputeMissingStrategyOneLoading,
  } = useQuery(
    "getImputeMissingStrategyOneData",
    async () => {
      setError("");
      const getUserEmail = getUserInfo()?.email;
      const response = await imputeMissingStrategyOne(getUserEmail, dataId);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        }

        try {
          // Replace 'None' with 'null'
          // let jsonString = data?.data?.imputed_values.replace(/None/g, 'null');

          // // Replace 'nan' with 'null'
          // jsonString = jsonString.replace(/nan/g, 'null');

          let jsonArray = JSON.parse(data?.data?.imputed_values);
          setImputedData(data?.data?.imputed_data);
          setImputedValues(JSON.parse(data?.data?.imputed_values));
          console.log(jsonArray);
        } catch (error) {
          console.error("Invalid JSON:", error);
        }
        // let jsonArray = JSON.parse(data?.data?.imputed_values);
        // console.log(data?.data?.imputed_values, "impute data");
        // setMissingImputeData(process.env.REACT_APP_BASE_URL + "get_image/" +data?.data?.heatmap_before)
        // setDataDropInfo(data?.data?.cleaned);
        // setAllMissingDataColumns(data?.data?.all_columns_with_missing_data);
      },
      onError: () => {
        setError("Oops! Failed to load result");
      },
    },
    {
      enabled: shouldFetchStrategyData === "strategyone",
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataInfoFetch();
      getMissingPercentage();
      // getImputeMissingStrategyOne();
    }, 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getDataInfoFetch, getMissingPercentage]);

  const secondaryList = [
    {
      name: "Missing Values in % (Info)",
      id: "missingpercent",
    },
    {
      name: "Missing Values in % (Visualizer)",
      id: "missingpercentvisualizer",
    },
  ];

  const secondaryMissingList = [
    {
      name: "Columns (After Drop Action)",
      id: "afterdropview",
    },
    {
      name: "Missing Values in % (Visualizer)",
      id: "missingpercentvisualizer",
    },
  ];

  const handleStrategyAction = (type) => {
    if (type === "strategyone") {
      return setShouldFetchStrategyData("strategyone");
    }
    if (type === "strategytwo") {
      return setShouldFetchStrategyData("strategytwo");
    }
  };

  const downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;

    // CSV file
    csvFile = new Blob([csv], { type: "text/csv" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
    setDownloadLoading(false)
  };

  const exportTableToCSV = (filename) => {
    var csv = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);

      csv.push(row.join(","));
    }

    // Download CSV file
    downloadCSV(csv.join("\n"), filename);
  };

  const startCsvDownload = (filename) => {
    setDownloadLoading(true)
    setTimeout(() => {
      exportTableToCSV(filename)
    }, 4000)
  }
  const renderTable = () => {
    if (imputedData.length === 0) return null;

    const columns = Object.keys(imputedData[0]);

    return (
      <div
        className="MissingValuesTable"
        style={{
          overflow: "auto",
        }}
      >
        <h3
          style={{
            marginBottom: "5px",
          }}
        >
          Data Table After Imputation [Strategy One]:
        </h3>
        <h6
          style={{
            marginBottom: "5px",
            color: "#112143",
            backgroundColor: "#4249962a",
            width: "fit-content",
            padding: "10px",
            border: "2px solid #131035",
            fontWeight: "normal",
          }}
        >
          Strategy: Imputes missing values in a DataFrame, handling numerical
          and categorical columns separately; strategy='median' & 'most
          frequent'
        </h6>
        <h6
          style={{
            marginBottom: "5px",
            color: "#272b41",
            // backgroundColor: "#f0c0812a",
            width: "fit-content",
            padding: "10px",
            display: "flex",
          }}
        >
          <i>Note: Imputed value(s) have the Color box:</i>
          &nbsp;
          <div
            style={{
              width: "70px",
              padding: "10px",
              backgroundColor: "#94dba4",
            }}
          ></div>
        </h6>
        <br />
        <button
          className={`btn-success ${downloadLoading ? 'btn-transparent-success' : ""}`}
          onClick={() =>
            startCsvDownload("new-data-after-imputation-strategy-one.csv")
          }
          disabled={downloadLoading}
        >{downloadLoading ? "Downloading New Data CSV file..." :
          <><img
            src="https://www.freeiconspng.com/uploads/white-download-icon-png-32.png"
            width="40px"
            alt="download-icon"
          />
          Download New Data (.csv)
          </>
        }
        </button>
        <br />
        <br />
        <table
          style={{
            fontFamily: "Arial, sans-serif",
            borderCollapse: "collapse",
            maxWidth: "100%",
          }}
          border="1"
        >
          <thead style={{ backgroundColor: "#f2f2f2" }}>
            <tr>
              {columns.map((col) => (
                <th
                  key={col}
                  style={{
                    border: "1px solid #ddd",
                    padding: "8px",
                    textAlign: "left",
                  }}
                >
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {imputedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col) => (
                  <td
                    key={col}
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "left",
                      backgroundColor:
                        imputedValues[rowIndex][col] !== null ? "#94dba4" : "",
                    }}
                  >
                    {row[col]}
                    {/* {imputedValues[rowIndex][col] !== null && ` (imputed)`} */}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className="col-12 mb-25">
        {isLoadingColumns && (
          <div>
            <img src={Loader} alt="loader" className="svg" height="100px" />
          </div>
        )}
        {error && (
          <p
            style={{
              color: "red",
            }}
          >
            {error}
          </p>
        )}
        {action === "handlingmissing" && (
          <div>
            {" "}
            <br />
            <ul class="atbd-breadcrumb nav">
              <li class="atbd-breadcrumb__item">
                <a
                  href="#?"
                  onClick={() => {
                    setAction("view");
                    setSecondaryDataView("missingpercent");
                    setShouldFetchStrategyData("");
                  }}
                >
                  Back to Studio Home
                </a>
                <span class="breadcrumb__seperator">
                  <span class="la la-angle-right"></span>
                </span>
              </li>
              <li class="atbd-breadcrumb__item">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      "https://cdn-icons-png.freepik.com/512/8438/8438962.png"
                    }
                    alt="loader"
                    className="svg"
                    height="25px"
                  />
                  &nbsp;
                  <ExploreUnitHeader
                    title={
                      "Handling Missing Data [Columns (After Drop Action of Columns with over 40% missing data)]"
                    }
                    fontSize="15px"
                    addHr={false}
                  />
                </div>
              </li>
            </ul>
            <br />
            <hr style={{ margin: "5px" }} />
            {/* {secondaryMissingList.map((item, index) => {
              return (
                <a
                  href="#?"
                  onClick={() => setSecondaryDataView(item.id)}
                  style={{
                    fontSize: "14px",
                    color: item.id === secondaryDataView ? "#fff" : "#936d24",
                    marginRight: "10px",
                    background:
                      item.id === secondaryDataView ? "#936d24" : "none",
                    padding: "5px",
                  }}
                  key={index + item.id}
                >
                  {item.name}
                </a>
              );
            })} */}
            {dataDropInfo && (
              <>
                {" "}
                {/* <hr style={{ margin: "5px" }} /> */}
                <div className="MissingValues-drop-list ">
                  <pre>{dataDropInfo}</pre>
                </div>
                <div
                  className="MissingValues-drop"
                  style={{
                    width: "fit-content",
                  }}
                >
                  Fill in the missing values for column(s){" "}
                  <b>{allMissingDataColumns?.join(", ")}</b> using our two
                  imputation strategies:
                  <div
                    style={{
                      // textAlign: "right",
                      marginTop: "20px",
                    }}
                  >
                    <div>
                      <b>Strategy One: </b> Imputes missing values in a
                      DataFrame, handling numerical and categorical columns
                      separately; strategy='median' & 'most frequent'
                    </div>
                    <br />
                    <div>
                      <b>Strategy Two: </b> Imputes missing values in a
                      DataFrame, handling numerical and categorical columns
                      separately. Uses KNNImputer for numerical columns and
                      most_frequent strategy for categorical columns.
                    </div>
                    <br />
                    <button
                      className="btn-dark"
                      onClick={() => {
                        handleStrategyAction("strategyone");
                        setAction("strategyoneimpute");
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-layers"
                      >
                        <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                        <polyline points="2 17 12 22 22 17"></polyline>
                        <polyline points="2 12 12 17 22 12"></polyline>
                      </svg>
                      &nbsp; Run Strategy One
                    </button>
                    <button
                      className="btn-dark"
                      onClick={() => {
                        setAction("handlingmissing");
                        setSecondaryDataView("afterdropview");
                        getMissingDroppedColumn();
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-layers"
                      >
                        <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
                        <polyline points="2 17 12 22 22 17"></polyline>
                        <polyline points="2 12 12 17 22 12"></polyline>
                      </svg>
                      &nbsp; Run Strategy Two
                    </button>
                  </div>
                  {/* <table>
                    <thead>
                      <tr>
                        {Object.keys(data.changes[0]).map((key) => (
                          <th key={key}>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {data.changes.map((row, index) => (
                        <tr key={index}>
                          {Object.values(row).map((value, i) => (
                            <td key={i}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </div>
                <img src={missingImputeData} alt="boxplot" width="75%" />
              </>
            )}
          </div>
        )}
        {action === "strategyoneimpute" && (
          <>
            <ul class="atbd-breadcrumb nav">
              <li class="atbd-breadcrumb__item">
                <a
                  href="#?"
                  onClick={() => {
                    setAction("handlingmissing");
                    setSecondaryDataView("afterdropview");
                    setShouldFetchStrategyData("");
                  }}
                >
                  Back to Missing Data Handler
                </a>
                <span class="breadcrumb__seperator">
                  <span class="la la-angle-right"></span>
                </span>
              </li>
              <li class="atbd-breadcrumb__item">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      "https://cdn-icons-png.freepik.com/512/8438/8438962.png"
                    }
                    alt="loader"
                    className="svg"
                    height="25px"
                  />
                  &nbsp;
                  <ExploreUnitHeader
                    title={"Imputation [Strategy One]"}
                    fontSize="15px"
                    addHr={false}
                  />
                </div>
              </li>
            </ul>
            <br />
            {renderTable()}
          </>
        )}
        {!isLoadingColumns && action === "view" && (
          <div>
            <br />
            <ExploreUnitHeader title={"Percentage (%) of missing values:"} />
            {secondaryList.map((item, index) => {
              return (
                <a
                  href="#?"
                  onClick={() => setSecondaryDataView(item.id)}
                  style={{
                    fontSize: "14px",
                    color: item.id === secondaryDataView ? "#fff" : "#936d24",
                    marginRight: "10px",
                    background:
                      item.id === secondaryDataView ? "#936d24" : "none",
                    padding: "5px",
                  }}
                  key={index + item.id}
                >
                  {item.name}
                </a>
              );
            })}
            <hr style={{ margin: "5px" }} />
            <br />
            {secondaryDataView === "missingpercent" && (
              <>
                {missingColumns?.length > 0 && (
                  <div className="MissingValues-drop">
                    Based on the outcome, you have column(s) with at least 40%
                    missing data, do you want to go ahead with dropping the
                    column(s); <b>{missingColumns?.join(", ")}</b> ?{" "}
                    <div
                      style={{
                        textAlign: "right",
                        marginTop: "10px",
                      }}
                    >
                      <button
                        className="btn-primary"
                        onClick={() => {
                          setAction("handlingmissing");
                          setSecondaryDataView("afterdropview");
                          getMissingDroppedColumn();
                        }}
                      >
                        YES, drop columns
                      </button>
                    </div>
                  </div>
                )}
                <table>
                  <thead>
                    <tr>
                      <th>Column Name</th>
                      <th>Percentage Missing</th>
                    </tr>
                  </thead>
                  <tbody>
                    {missingDataInfo.map((item, index) => {
                      const [columnName, percentage, missingCount] =
                        item.split(" - ");
                      let numberValue = parseFloat(percentage.replace("%", ""));

                      return (
                        <tr key={index + "missing-value"}>
                          <td
                            style={{
                              width: "fit-content",
                              padding: "8px 20px 8px 0px",
                              color: numberValue > 0 ? "#cd1c3c" : "#000",
                            }}
                          >
                            {columnName}
                          </td>
                          <td
                            style={{
                              color: numberValue > 0 ? "#cd1c3c" : "#000",
                            }}
                          >
                            {percentage} - ({missingCount})
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
            {secondaryDataView === "missingpercentvisualizer" &&
              !missingPercentLoading && (
                <img src={dataInfo} alt="boxplot" width="75%" />
              )}
            {/* <pre>{dataInfo}</pre> */}
          </div>
        )}
        <br />
      </div>
    </>
  );
};

export default MissingValues;
