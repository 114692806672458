import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { infoApi } from "api/explore";
import Loader from "../../assets/img/loader.svg";
import ExploreUnitHeader from "./ExploreUnitHeader";
import { getUserInfo } from "utils/authHandler";

const DataInfo = ({ match, id }) => {
  const [error, setError] = useState("");
  const [dataInfo, setDataInfo] = useState("");
  const dataId = match?.params?.id || id;

  const { refetch: getDataInfoFetch, isLoading: isLoadingColumns } = useQuery(
    "getDataInfo",
    async () => {
      const getUserEmail = getUserInfo()?.email;
      const response = await infoApi(getUserEmail, dataId);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        }
        setDataInfo(data?.data?.info);
      },
      onError: () => {
        setError("Oops! Failed to load data info.");
      },
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataInfoFetch();
    }, 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getDataInfoFetch]);

  return (
    <>
      <div className="col-12 mb-25">
        {isLoadingColumns && (
          <div>
            <img src={Loader} alt="loader" className="svg" height="100px" />
          </div>
        )}
        {error && (
          <p
            style={{
              color: "red",
            }}
          >
            {error}
          </p>
        )}
        <div>
        <br />
        <ExploreUnitHeader title={"Data Info:"}/>
          <pre>{dataInfo}</pre>
        </div>
        <br />
        {/* <div>
          <p><b>Columns List with Type:</b></p>
          {dataInfoWithType}
        </div> */}
      </div>
    </>
  );
};

export default DataInfo;
