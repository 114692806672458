import React from "react";
import "./css/Head.css";

const ExploreUnitHeader = ({ title, fontSize = "17px", addHr = true }) => {
  return (
    <>
      <div
        // className="alert alert-info"
        style={{
          // backgroundColor: "#44467c",
          color: "black",
          width: "fit-content",
          fontSize,
        }}
      >
        <b>{title}</b>
      </div>
      {addHr && <hr style={{ margin: "5px" }} />}
    </>
  );
};

export default ExploreUnitHeader;
