import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import Loader from "../../assets/img/loader.svg";
import VisualIcon from "../../assets/img/visual-icon2.png";

import { numColsApi } from "api/explore";

import "./css/Exploratory.css";
import Boxplots from "./Visualizations/Boxplots";
import Countplots from "./Visualizations/Countplots";
import PieCharts from "./Visualizations/PieCharts";
import { getUserInfo } from "utils/authHandler";

const ExploratoryVisualize = ({ dataId }) => {
  const [error, setError] = useState("");
  const [view, setView] = useState("boxplot");
  const [dataColumns, setDataColumns] = useState([]);
  const [allDataColumns, setAllDataColumns] = useState([]);

  const { refetch: getDataInfoFetch, isLoading: isLoadingColumns } = useQuery(
    "getNumColsData",
    async () => {
      setError("")
      const getUserEmail = getUserInfo()?.email;
      const response = await numColsApi(getUserEmail, dataId);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        }

        const elements = data?.data?.numeric_columns?.slice(1, -1).split(" ");
        const allCols = data?.data?.all_data_columns?.slice(1, -1).split(" ");

        // Create an array by mapping over the elements and removing the single quotes
        const resultArray = elements.map((element) =>
          element.replace(/'/g, "")
        );

        const resultArray2 = allCols.map((element) =>
          element.replace(/'/g, "")
        );

        const newArray = resultArray.filter((element) => element !== "");

        setDataColumns(newArray);
        setAllDataColumns(resultArray2)
      },
      onError: () => {
        setError("Oops! Failed to load data visualization");
      },
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataInfoFetch();
    }, 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getDataInfoFetch]);

  const getCurrentView = () => {
    switch (view) {
      case "boxplot":
        return <Boxplots id={dataId} dataColumns={dataColumns} />;
      case "countplot":
        return <Countplots id={dataId} dataColumns={allDataColumns} />;
      case "piechart":
        return <PieCharts id={dataId} dataColumns={allDataColumns} />;
      // case "head":
      //   return <Head id={dataId} />;
      // case "tail":
      //   return <Tail id={dataId} />;
      // case "isnull":
      //   return <IsNull id={dataId} />;
      // case "describe":
      //   return <Describe id={dataId} />;
      default:
        return <div>No view</div>;
    }
  };

  const generalStatButtons = [
    {
      name: "Box Plots",
      id: "boxplot",
    },
    {
      name: "Count Plots",
      id: "countplot",
    },
    {
      name: "Pie Charts",
      id: "piechart",
    },
    // {
    //   name: " View All Columns",
    //   id: "columns",
    // },
    // {
    //   name: "View Data Info",
    //   id: "info",
    // },
    // {
    //   name: "Data Head (First 5)",
    //   id: "head",
    // },
    // {
    //   name: "Data Tail (Last 5)",
    //   id: "tail",
    // },
    // {
    //   name: "View Describe Info",
    //   id: "describe",
    // },
    // {
    //   name: "View isNull Total",
    //   id: "isnull",
    // },
  ];

  const updateView = (id) => {
    setView(id);
  };

  const buttons = generalStatButtons.map((button) => (
    <button
      key={button.id}
      className={`btn ${
        view === button.id ? "btn-dark" : "btn-light"
      } btn-default btn-squared`}
      onClick={() => updateView(button.id)}
    >
      {button.name}
    </button>
  ));

  return (
    <>
      <div className="col-79 col-lg-9 col-sm-8">
        <div className="fileM-grid-wrapper mb-30 ">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <h6 className="fileM-wrapper__title">
                <img src={VisualIcon} alt="info" height="25px" /> &nbsp;
                Visualizations: Univariate
              </h6>
              <div className="fileM-wrapper">
                <div className="atbd-button-list d-flex flex-wrap">
                  <div className="button-list-container">
                    <div className="button-list">{buttons}</div>
                  </div>
                </div>
                <hr style={{ margin: "5px" }} />
                {error && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    <br />
                    {error}
                  </p>
                )}
                {isLoadingColumns && (
                  <div>
                    <img
                      src={Loader}
                      alt="loader"
                      className="svg"
                      height="100px"
                    />
                  </div>
                )}
                {dataColumns?.length > 0 && dataColumns !== "[]" && (
                  <div className="row">{getCurrentView()}</div>
                )}
                {!isLoadingColumns && dataColumns?.length < 1 && (
                  <>
                    <br />
                    <div className=" alert alert-info ">
                      No {view} for this data.
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExploratoryVisualize;
