import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { uniCountApi } from "api/explore";
import Loader from "../../../assets/img/loader.svg";
import { getUserInfo } from "utils/authHandler";

const Countplots = ({ match, id, dataColumns }) => {
  const [error, setError] = useState("");
  const [dataInfo, setDataInfo] = useState("");
  const [plotColumn, setPlotColumn] = useState(dataColumns[0]);
  const dataId = match?.params?.id || id;

  const { refetch: getDataInfoFetch, isLoading } = useQuery(
    "getCountplotsData",
    async () => {
      setError("")
      const getUserEmail = getUserInfo()?.email;
      const response = await uniCountApi(getUserEmail, dataId, plotColumn);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        }
        setDataInfo(
          process.env.REACT_APP_BASE_URL + "get_image/" + data?.data?.plot
        );
      },
      onError: () => {
        setError("Oops! Failed to load data visualization");
      },
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataInfoFetch();
    }, 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getDataInfoFetch]);

  const handleSelectChange = (event) => {
    setPlotColumn(event.target.value);
  };

  const getBoxPlot = () => {
    getDataInfoFetch();
  };

  return (
    <>
      <div className="col-12 mb-25 text-center">
        {error && (
          <p
            style={{
              color: "red",
            }}
          >
            {error}
          </p>
        )}
        <div
          className="flex"
          style={{
            display: "flex",
            gap: "15px",
            alignItems: "baseline",
            color: "#5a5f7d",
            fontSize: "14px",
            marginTop: "15px",
          }}
        >
          <p>Show a count plot for:</p>
          <select
            className="form-control"
            style={{ width: "fit-content" }}
            value={plotColumn}
            onChange={handleSelectChange}
          >
            {dataColumns.map((option, index) => (
              <option key={index}>{option}</option>
            ))}
          </select>
          <button className="btn btn-sm btn-primary" onClick={getBoxPlot}>
            Show
          </button>
        </div>
        {isLoading && (
          <div>
            <img src={Loader} alt="loader" className="svg" height="100px" />
          </div>
        )}
        {!isLoading && (
          <div>
            <br />
            <img src={dataInfo} alt="plot" width="80%"/>
          </div>
        )}
        <br />
      </div>
    </>
  );
};

export default Countplots;
