import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { tailApi } from "api/explore";
import Loader from "../../assets/img/loader.svg";
import "./css/Head.css";
import ExploreUnitHeader from "./ExploreUnitHeader";
import { getUserInfo } from "utils/authHandler";

const Tail = ({ match, id }) => {
  const [error, setError] = useState("");
  const [dataInfo, setDataInfo] = useState("");
  const [rows, setRows] = useState(5);

  const dataId = match?.params?.id || id;

  const { refetch: getDataFetch, isLoading } = useQuery(
    "getTailData",
    async () => {
      const getUserEmail = getUserInfo()?.email;
      const response = await tailApi(getUserEmail, dataId, rows);
      return response;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.status === 404) {
          setError(data?.data?.message);
        }

        setDataInfo(data?.data?.tail);
      },
      onError: () => {
        setError("Oops! Failed to load data");
      },
    }
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataFetch();
    }, 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getDataFetch]);

  const HeadValuesTable = ({ headValues }) => {
    const serialNumbers = headValues["age"]
      ? Object.keys(headValues["age"])
      : []; // Check if headValues['age'] is defined
    return (
      <div className="notebook-container">
        <div className="notebook-scrollable">
          <div
            className="notebook-column"
            style={{
              minWidth: "50px",
            }}
          >
            <div className="notebook-column-header">s/n</div>
            {serialNumbers.map((index, i) => (
              <div key={index} className="notebook-row">
                {i + 1}
              </div>
            ))}
          </div>
          {Object.entries(headValues).map(([columnName, columnValues]) => (
            <div key={columnName} className="notebook-column">
              <div className="notebook-column-header">{columnName}</div>
              {Object.entries(columnValues).map(([index, value], i) => (
                <div
                  key={index}
                  className={`notebook-row ${i % 2 === 0 ? "even-row" : ""}`}
                >
                  {value}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const handleInputChange = (event) => {
    event.persist();
    setError("");
    setRows(event.target.value);
  };

  const numberOfRowHandler = () => {
    getDataFetch();
  };

  return (
    <>
      <div className="col-12 mb-25">
        {error && (
          <p
            style={{
              color: "red",
            }}
          >
            {error}
          </p>
        )}
        <div>
          <br />
          <ExploreUnitHeader title={"Data Tail (Default: Last 5):"}/>
          <div
            className="flex"
            style={{
              display: "flex",
              gap: "15px",
              alignItems: "baseline",
              color: "#5a5f7d",
              fontSize: "14px",
              marginTop: "15px",
            }}
          >
            <p>Display number of rows:</p>
            <input
              id="number"
              type="number"
              value={rows}
              onChange={handleInputChange}
              className="form-control"
              style={{
                width: "20%",
              }}
            />{" "}
            <button
              className="btn btn-sm btn-primary"
              onClick={numberOfRowHandler}
            >
              Show
            </button>
          </div>
          <br />
          {/* <pre>{dataInfo}</pre> */}
          {isLoading && (
            <div>
              <img src={Loader} alt="loader" className="svg" height="100px" />
            </div>
          )}
          <HeadValuesTable headValues={dataInfo} />
        </div>
        <br />
      </div>
    </>
  );
};

export default Tail;
