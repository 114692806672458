import React, { useState } from "react";
import StatIcon from "../../assets/img/clean-data.png";

import "./css/Exploratory.css";
import MissingValues from "./DataCleaners/MissingValues";
import GeneralCorrelation from "./GeneralCorrelation";

const CleanDataInsightExploratory = ({ dataId }) => {
  const [error, ] = useState("");
  const [view, setView] = useState("missing");

  const getCurrentView = () => {
    switch (view) {
      case "missing":
        return <MissingValues id={dataId} />;
      case "corr":
        return <GeneralCorrelation id={dataId} />;
      default:
        return <div>No view</div>;
    }
  };

  const generalStatButtons = [
    {
      name: "Missing Value(s)",
      id: "missing",
    },
    {
      name: "Data Correlations",
      id: "corr",
    },
  ];

  const updateView = (id) => {
    setView(id);
  };

  const buttons = generalStatButtons.map((button) => (
    <button
      key={button.id}
      className={`btn ${
        view === button.id ? "btn-dark" : "btn-light"
      } btn-default btn-squared`}
      onClick={() => updateView(button.id)}
    >
      {button.name}
    </button>
  ));

  return (
    <>
      <div className="col-79 col-lg-9 col-sm-8">
        <div className="fileM-grid-wrapper mb-30 ">
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div style={{
              }}>
              <h6 className="fileM-wrapper__title" style={{
                fontSize: "22px"
              }}>
                <img src={StatIcon} alt="info" height="30px" style={{
                  position: "relative",
                  top: "-4px"
                }}/> &nbsp;
               Clean Data Studio
               <hr style={{ margin: "5px" }} />
              </h6>
              </div>
              <div className="fileM-wrapper">
                <div className="atbd-button-list d-flex flex-wrap">
                  <div className="button-list-container">
                    <div className="button-list">{buttons}</div>
                  </div>
                </div>
                <hr style={{ margin: "5px" }} />
                {error && (
                  <p
                    style={{
                      color: "red",
                    }}
                  >
                    <br />
                    {error}
                  </p>
                )}
                {/* {isLoadingColumns && (
                  <div>
                    <img
                      src={Loader}
                      alt="loader"
                      className="svg"
                      height="100px"
                    />
                  </div>
                )} */}
                {(
                  <div className="row">{getCurrentView()}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CleanDataInsightExploratory;
