import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { columnsApi } from "api/explore";
import Loader from "../../assets/img/loader.svg";
import { getUserInfo } from "utils/authHandler";

const AllColumns = ({ match, id }) => {
  const [error, setError] = useState("");
  const [dataColumns, setDataColumns] = useState("");
  const [dataColumnsWithType, setDataColumnsWithType] = useState("");

  const dataId = match?.params?.id || id;

  const { refetch: getDataColumnsFetch, isLoading: isLoadingColumns } =
    useQuery(
      "getDataColumns",
      async () => {
        const getUserEmail = getUserInfo()?.email;
        const response = await columnsApi(getUserEmail, dataId);
        return response;
      },
      {
        onSuccess: (data) => {
          if (data?.data?.status === 404) {
            setError(data?.data?.message);
          }

          const array = data?.data?.columns?.slice(1, -1).split("' '");

          // Join the array with commas
          const csvString = array.join(", ");
          setDataColumns(csvString);
          setDataColumnsWithType(data?.data?.withtype)
        },
        onError: () => {
          setError("Oops! Failed to load columns.");
        },
      }
    );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getDataColumnsFetch();
    }, 1000);

    // Cleanup function
    return () => {
      clearTimeout(timeoutId);
    };
  }, [getDataColumnsFetch]);

  return (
    <>
      <div className="col-12 mb-25">
        {isLoadingColumns && (
          <div>
            <img src={Loader} alt="loader" className="svg" height="100px" />
          </div>
        )}
        {error && (
          <p
            style={{
              color: "red",
            }}
          >
            {error}
          </p>
        )}
        <br />
        <div>
          <p><b>Columns List:</b></p>
          {dataColumns}
        </div>
        <br />
        <div>
          <p><b>Columns List with Type:</b></p>
          <pre>{dataColumnsWithType}</pre>
        </div>
      </div>
    </>
  );
};

export default AllColumns;
